<template>
  <div class="base-content">
    <query-frame
      :pageVisible="false"
      :current="page.current"
      :total="page.total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
<!--        <query-item label="车辆分类">-->
<!--          <j-category-car-select-->
<!--            style="width: 160px"-->
<!--            placeholder="请选择分类"-->
<!--            v-model="query.carCategoryId"-->
<!--          />-->
<!--        </query-item>-->
        <query-item label="状态">
          <a-select allowClear v-model="query.status">
            <a-select-option :value="0">
              启用
            </a-select-option>
            <a-select-option :value="1">
              禁用
            </a-select-option>
          </a-select>
        </query-item>
      </template>
      <!-- 查询条件结束 -->

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import { vehicleCategoryMenuListByStaus, vehicleCategorySubmit, vehicleCategoryDelete } from '@/api/vehicle';
import queryMixin from '@/mixin/queryMixin';
import { formatDate } from '@/libs/utils';
// import JCategoryCarSelect from "@/components/JCategoryCarSelect";
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil';
import { loadCategoryCarData } from '@/api/manage';

export default {
  name: 'vehicleCategory',
  mixins: [queryMixin],
  components: {
    // JCategoryCarSelect
  },
  props: {},
  data() {
    return {
      query: {
        status: 0,
        carCategoryId: null,
        kartClassifyName: null,
      },
      // 默认展示表格
      columns: [
        {
          title: '序号',
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '门店名称',
          dataIndex: 'shopName',
          key: 'shopName',
          width: 200,
        },
        {
          title: '类型名称',
          dataIndex: 'kartClassifyName',
          key: 'kartClassifyName',
          width: 200,
        },
        {
          title: '类型编码',
          dataIndex: 'kartClassifyCode',
          key: 'kartClassifyCode',
          width: 120,
        },
        {
          title: '车辆规格',
          dataIndex: 'kartClassifySpec',
          key: 'kartClassifySpec',
          ellipsis: true,
          width: 120,
        },
        {
          title: '有效时间',
          dataIndex: 'endTime',
          key: 'endTime',
          ellipsis: true,
          width: 200,
          customRender: (text, row) => {
            return (
              <section>{row.periodType === 0 ? '长期有效' : `${row.startDate} - ${row.endDate}`}</section>
            );
          }

        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width: 120,
          customRender: (text, row) => {
            return (
              <a-switch checked-children="启用" un-checked-children="禁用" checked={row.status === 0} onClick={ () => this.handleStatus(row)}/>
            );
          }
        },
        {
          title: '分类',
          dataIndex: 'carCategoryId',
          key: 'carCategoryId',
          ellipsis: true,
          width: 200,
          customRender: (text) => (text ? filterMultiDictText(this.dictOptions.carCategoryId, text) : ''),
        },
        {
          title: '数据标签',
          dataIndex: 'dataLabel',
          key: 'dataLabel',
          ellipsis: true,
          width: 120,
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 200,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return (
              <section>
                <a-button type="link" size="small" onClick={ () => this.handleInfo(row) }>查看</a-button>
                <a-divider type="vertical"></a-divider>
                <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
                <a-divider type="vertical"></a-divider>
                <a-button type="link" size="small" onClick={ () => this.handleDelete(row) }>删除</a-button>
              </section>
            );
          }
        }
      ],
      dictOptions: {},
      dataList: []
    };
  },
  computed: {},
  watch: {},
  created() {
    this.handleQuery();
    this.initDictConfig();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取列表
    getData() {
      this.dataList = [];
      vehicleCategoryMenuListByStaus(this.params).then((data) => {
        data.forEach((item) => {
          item.key = item.kartClassifyId;
          if (item.startDate) {
            item.startDate = formatDate(item.startDate);
          }
          if (item.endDate) {
            item.endDate = formatDate(item.endDate);
          }
        });
        this.dataList = data;
        // console.log(this.dataList)
      });
    },

    // 添加
    handleAdd() {
      this.$router.push({
        name: 'vehicleCategoryAdd'
      });
    },

    // 查看
    handleInfo(row) {
      this.$router.push({
        name: 'vehicleCategoryInfo',
        params: {
          id: row.kartClassifyId
        }
      });
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'vehicleCategoryEdit',
        params: {
          id: row.kartClassifyId
        }
      });
    },
    // 编辑
    handleDelete(row) {
      const self = this;
      this.$confirm({
        title: '删除车辆类型',
        content: '此操作将永远删除该车辆类型，您确定要这么做吗？',
        okType: 'danger',
        onOk() {
          return new Promise((resolve, reject) => {
            console.log(row.kartClassifyId);
            vehicleCategoryDelete(row.kartClassifyId).then(() => {
              self.$message.success('删除成功');
              self.getData();
              resolve();
            }).catch(() => {
              reject();
            });
          }).catch((e) => console.log(e));
        },
        onCancel() {},
      });
    },
    // 启用或者禁用
    handleStatus(row) {
      const params = {
        kartClassifyId: row.kartClassifyId,
        kartClassifyName: row.kartClassifyName,
        kartClassifySpec: row.kartClassifySpec,
        status: row.status === 0 ? 1 : 0
      };

      vehicleCategorySubmit(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
    initDictConfig() {
      loadCategoryCarData({ code: '0' }).then((res) => {
        // console.log(res)
        if (res !== undefined) {
          this.$set(this.dictOptions, 'carCategoryId', res);
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
